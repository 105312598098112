@import "../style.scss";

.header{
    display : flex;
    align-items: flex-end;
    width : 100%; 
    height: 100vh;
    background-color: $gris;
    padding : 0 10vw 12vh 10vw;

    @media screen and (min-width : 300px) and (max-width : 1000px) {
        flex-direction : column;
        align-items: flex-start;
        gap : 3vh;
        padding : 30px;
        height : 100svh;
    }

    .hero{
        position : relative;
        display : flex;
        justify-content: space-between;
        align-items: flex-end;
        width : 100%;
        height : 73vh;

        @media screen and (min-width : 300px) and (max-width : 1000px) {
            flex-direction: column;
            height : 100%;
        }

        &_container{
            position : relative;
            display : flex;
            flex-direction: column;
            justify-content: space-between;
            width : 40vw;
            height : 63vh;
            padding-top: 5vh;
            z-index : 5;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                width : 100%;
                height : auto;
                padding-top : 0;
                margin-top : 7vh;
            }

            &-subtitle{
                display : flex;
                align-items: center;
                gap : 1vw;
                transform : translateY(100%);
                opacity : 0;
                animation : slideUp .5s ease 1s forwards;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    display : none;
                    position : absolute;
                    bottom : calc(-100% + (-11.5vh));
                    left : 0;
                    align-items: center;
                    gap : 2.5vw;
                }

                .bullet{
                    width : 1vw;
                    height : 1vw;
                    background-color: $primary;
                    border-radius : 100vmax;

                    @media screen and (min-width : 300px) and (max-width : 749px) {
                        width : 5vw;
                        height : 5vw;
                    }

                    @media screen and (min-width : 750px) and (max-width : 1000px) {
                        width : 3.5vw;
                        height : 3.5vw;
                    }
                }

                p{
                    font-variation-settings:
                    "wdth" 125,
                    "wght" $semibold;

                    @media screen and (min-width : 300px) and (max-width : 749px) {
                        font-size : 4vw;
                    }

                    @media screen and (min-width : 750px) and (max-width : 1000px) {
                        font-size : 3.5vw;
                    }
                }
            }

            &-arrow{
                position : absolute;
                right : -9vw;
                bottom : 1.5vw;
                width : 11vw;
                height : auto;
                transform : rotate(-38deg);

                @media screen and (min-width : 300px) and (max-width : 749px) {
                    right : unset;
                    left : 5vw;
                    bottom : -5vh;
                    transform : rotate(5deg);
                    width : 23vw;
                }

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    right : unset;
                    left : 5vw;
                    bottom : -13vh;
                    transform : rotate(5deg);
                    width : 23vw;
                }

                svg{
                    width : 100%;
                    height : 100%;

                    path{
                        stroke-dasharray: 1000; 
                        stroke-dashoffset: 1000;

                        @media screen and (min-width : 300px) and (max-width : 1000px) {
                            stroke-width : 5px;
                        }

                        &:nth-last-of-type(2){
                            animation: drawPath 2s ease 1.5s forwards;
                        }
    
                        &:nth-last-of-type(1){
                            animation: drawPath 2s ease 2.4s forwards;
                        }
                    }
                }
            }
        }

        &_loading{
            position : absolute;
            bottom : 0;
            right : 0;
            background-color: $primary;
            border : 4px solid $noir;
            border-radius : 50px;
            width : 100%;
            height : 100%;
            overflow : hidden;
            animation : headerImg 1s ease .7s forwards;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                border-radius : 30px;
                height : 100%;
                animation : headerImgMobile 1s ease .7s forwards;
            }

            &::after{
                position: absolute;
                content: "";
                top: -100%;
                left: -50%;
                width: 300%;
                height: 300%;
                animation: grain 10s steps(10) infinite;
                background-image: url("../img/illustrations/noise-effect.png");
                opacity: .25;
                z-index : 2;
            }
        }

        &_img{
            position : relative;
            width : 28vw;
            height : auto;
            margin : 0 4px 0 0;      
            z-index : 5;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                display : flex;
                align-items: flex-end;
                width : 100%;
                height : 40vh;
                margin : 0 4px 4px 0;  
            }

            img{
                width : 100%;
                height : 100%;
                object-fit: contain;
                object-position : right bottom;
                border-radius : 0 0 47px 0;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : 100%;
                    height : 50vh;
                    border-radius : 0 0 26.5px 0;
                }
            }
        }

        
    }

    &_subtitle{
        align-items : center;
        gap : 1vw;
        width : 100%;
        animation : slideUp .5s ease 1s forwards;


        .bullet{
            width : 5vw;
            height : 5vw;
            background-color: $primary;
            border-radius : 100vmax;

            @media screen and (min-width : 750px) and (max-width : 1000px) {
                width : 3.5vw;
                height : 3.5vw;
            }
        }

        p{
            font-size : 4vw;
            font-variation-settings:
            "wdth" 125,
            "wght" $semibold;


            @media screen and (min-width : 750px) and (max-width : 1000px) {
                font-size : 3.5vw;
            }
        }
    }

    .rs{
        position : fixed;
        display : flex;
        flex-direction: column;
        gap : 1.3vh;
        right : 2vw;
        top : 50%;
        transform : translateY(-50%);
        z-index : 100;
        @media screen and (max-width : 990px) {
            display : none;
        }

        &_icon{           
            opacity : 0;
            transform : translateX(100%);

            &-bulle{
                display : flex;
                justify-content: center;
                align-items: center;
                width : 2.6vw;
                height : 2.6vw;
                border-radius : 100vmax;
                border : 3px solid $noir;
                background-color: $blanc;
                transition : transform .5s ease;
                will-change : transform;

                svg{
                    width : 1.3vw;
                    height : auto;
                    fill : $noir;
                    transition : transform .5s ease;
                    will-change: transform;
                }
            }
            
            &:hover{       
                cursor : url(../img/icons/cursor-pointer.svg), auto;
                .rs_icon-bulle{
                    background-color: $noir;
                    border-color : transparent;
                    transform : scale(1.1);
                }     
                
                svg{         
                    fill : $blanc;
                    transform : scale(.85);
                }
            }

            @for $i from 1 through $elements {
                &:nth-child(#{$i}) {
                    animation: slideSide .4s ease ($i * .2s) forwards;   
                }
            }
        }
    } 
}