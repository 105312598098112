*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    font-family : $font;
}

$elements: 100; 

body{
    cursor : url('../img/icons/cursor.svg'), auto;
}

html, body {
    scroll-behavior: smooth;
    background-color: $blanc;
    width : 100%;
}

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

input, button, fieldset{
    border: none;
    outline: none;
    background-color: transparent;
}

hr{
    outline: none;
    padding: 0;
    margin: 0;
}


.reveal{
    position : relative;
    width : 100%;
    height : 100%;
}


.hidden-mobile{
    @media screen and (max-width : 990px) {
        display : none !important;
     }
}

.hidden-pc{
    display : none !important;
    @media screen and (max-width : 990px) {
        display : flex !important;
    }
}