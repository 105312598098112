::-webkit-scrollbar {
    width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $noir; 
    border-radius: 10px;
}


/* Handle */
::-webkit-scrollbar-thumb {
    background: $noir; 
    border-radius: 10px;
    margin: 5px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $noir; 
}