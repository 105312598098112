@import "../style.scss";

.about{
    background-color: $gris;
    padding-top : 20vh;

    @media screen and (min-width : 300px) and (max-width : 1000px) {
        padding-top : 10vh;
    }

    &_intro{
        padding : 30vh 10vw 50vh 10vw;
        border-radius : 100px 100px 0 0;
        background-color: $noir;
        overflow : hidden;

        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 10vh 30px 30vh 30px;
            border-radius : 50px 50px 0 0;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 20vh 60px 30vh 60px;
            border-radius : 50px 50px 0 0;
        }

        &-container{
            position : relative;
            display : flex;
            flex-direction: column;
            gap : 5vh;
    
            &--subtitle{
                width : 60vw;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : 100%;
                }
            }
    
            &--circledText{
                position : absolute;
                bottom : -5vw;
                right : 0;
                width : 14vw;
                height : auto;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    bottom : -20vw;
                    width : 35vw;
                }

                svg{
                    width : 100%;
                    height : auto;
                    object-fit: contain;

                    .path{
                        stroke-dasharray: 1000; 
                        stroke-dashoffset: 1000;   
                        transition : 2s ease; 

                        &.active{
                            stroke-dashoffset: 0; 
                        }
                    }
                }
            }
        } 
    }

    &_presentation{
        padding : 40vh 10vw 50vh 10vw;
        border : 4px solid $noir;
        border-bottom : none;

        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 45vh 30px 40vh 30px;
            border-width : 3px;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 45vh 60px 40vh 60px;
            border-width : 3px;
        }

        &-container{
            display : flex;
            justify-content: space-between;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                flex-direction: column-reverse;
                gap : 15vh;
            }
            

            &--text{
                display : flex;
                flex-direction: column;
                gap : 10vh;
                width : 35vw;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : 100%;
                }

                .bouton{
                    &:hover{
                        background-color: $noir;
                        color : $blanc;
                    }
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 100%;
                    }
                }
            }

            &--img{
                position : relative;
                width : calc(35vw - 2vw);
                height : 85vh;
                margin-right : 2vw;
                margin-top : -60vh;
                z-index : 2;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : calc(100% - 5vw);
                    height : auto;
                    margin-right : 5vw;
                }

                img{
                    width : 100%;
                    height : 100%;
                    border-radius : 50px;
                    border : 4px solid $noir;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        border-radius : 30px;
                    }
                }

                &::after{
                    position : absolute;
                    content : "";
                    width : 100%;
                    height : 100%;
                    top : 2vw;
                    right : -2vw;
                    border : 4px solid $noir;
                    background-color: $primary;
                    border-radius : 50px;
                    z-index : -1;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        top : 5vw;
                        right : -5vw;
                        border-radius : 30px;
                    } 
                }
            }
        }
    }
    
    .sticky{
        position : relative;
        width : 100%;
        height : 430vh;
        margin-top : -20vh;
        z-index : 11;
        border : 4px solid $noir;
        border-top : none;

        @media screen and (min-width : 300px) and (max-width : 1000px) {
            margin-top : -15vh;
            border-width : 3px;
        }
        
        &_container{  
            position : sticky;
            top : 50px;    
            display : flex;
            justify-content: center;
            align-items: center;
            width : 100%;
            height : 130vh;
            padding : 0 10vw;
            background-color: $noir;
            border-radius : 100px 100px 0 0;
            overflow : hidden;
            padding-bottom : 30vh;

            @media screen and (min-width : 300px) and (max-width : 749px) {
                align-items: flex-start;
                border-radius : 50px 50px 0 0;
                padding : 10vh 30px 0 30px;
            }

            @media screen and (min-width : 750px) and (max-width : 1000px) {
                align-items: flex-start;
                border-radius : 50px 50px 0 0;
                padding : 10vh 60px 0 60px;
            }

            &::after {
                position: absolute;
                content: "";
                top: -100%;
                left: -50%;
                width: 300%;
                height: 300%;
                animation: grain 10s steps(10) infinite;
                background-image: url("../img/illustrations/noise-effect.png");
                opacity: .15;
                z-index : 2;
            }

            .woow{
                font-size : 12vw;
                line-height : 1;
                color : $primary;
                text-transform : uppercase;
                text-align : center;
                margin-top : -5vh;
                font-variation-settings:
                    "wdth" 125,
                    "wght" $heavy;

                @media screen and (min-width : 300px) and (max-width : 749px) {
                    font-size : 15vw;
                    margin-top : 20vh;
                }

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    font-size : 18vw;
                    margin-top : 20vh;
                }
            }

            .bouton, .etoile, .rubber{
                position : absolute;
            }

            .etoile{
                animation : scaleAnimation 1s linear infinite alternate;

                &:nth-of-type(1){
                    width : 5.7vw;
                    height : auto; 
                    right : 15vw;
                    top : 10vh;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 15vw;
                    }
                }

                &:nth-of-type(2){  
                    width : 6vw;
                    height : auto; 
                    left : 5vw;
                    top :  75vh;

                    @media screen and (min-width : 300px) and (max-width : 749px) {
                        top :  75vh;
                        left : 30px;
                        width : 13vw;
                    }

                    @media screen and (min-width : 750px) and (max-width : 1000px) {
                        top :  75vh;
                        left : 60px;
                        width : 13vw;
                    }
                }  

                img{
                    width : 100%;
                    height : 100%;
                    object-fit: contain;
                }          
            }

            .bouton{
                &:nth-of-type(2){  
                    left : 25vw;
                    top :  43vh;
                    transform : rotate(-10deg);
                   
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        display : none;
                        top :  26vh;
                        left : 20vw;
                        transform : rotate(-5deg);
                    }
                }  
                
                &:nth-of-type(1){
                    display : none;
                    right : 15vw;
                    top : 70vh;
                    transform : rotate(10deg);
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        top : 60vh;
                        right : 40vw;
                        //display : flex;
                    }
                }
            }

            .rubber{
                right : 26vw;
                top : 65vh;
                //transform : rotate(20deg);
                width : 8vw;
                height : 8vw;
                animation : leftRotation 2s ease-out infinite alternate;

                img{
                    width : 100%;
                    height : 100%;
                    object-fit: contain;
                }
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    display : none;
                    top : 60vh;
                    right : 13vw;
                    width : 25vw;
                }
            }

            &-content{
                display : flex;
                justify-content: space-between;
                gap : 5vw;
                width : 100%;
                margin-top : 10vh;
                @media screen and (min-width : 300px) and (max-width : 749px) {
                    flex-direction: column;
                    align-self: flex-start;
                    height : calc(90vh - 50px - 10vh);
                    margin-top : 0;
                }

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    flex-direction: column;
                    align-self: flex-start;
                    height : calc(90vh - 50px - 10vh);
                    margin-top : 0;
                }

                .txt{
                    width : 38vw;
                    height : fit-content;
                    padding : 5vw;
                    background-color: $blanc;
                    border : 4px solid $noir;
                    border-radius : 30px; 

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        display : flex;
                        justify-content: center;
                        align-items: center;
                        width : 100%;
                        height : calc(50% - 1.5vh);
                        padding : 10vw;
                    }

                    p{
                        line-height : 1.2;
                    }
                }
            }

            &:nth-of-type(2){
                background-color: transparent;

                &::after {
                    display : none;
                }

                .sticky_container-content{
                    justify-content: flex-start;
                }
                
            }

            &:nth-of-type(3){
                background-color: transparent;

                &::after {
                    display : none;
                }

                .sticky_container-content{
                    justify-content: flex-end;
                } 
            }  
        }
    }
}