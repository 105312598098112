.bouton{
    width : fit-content;
    height : fit-content;
    text-align : center;
    padding : 1.3vh 2vw;  
    border-radius : 100vmax;
    border-width: 4px;
    border-style: solid;
    background-color: transparent;
    text-transform : uppercase;
    transition : .3s ease;
    font-variation-settings:
        "wdth" 125,
        "wght" $semibold;

    @media screen and (max-width : 990px) {
        padding : 1vh 5vw;
        font-size : 4vw;
        border-width : 3px;
    }

    &:hover{
        cursor : url(../img/icons/cursor-pointer.svg), auto;
    }

    &_title{
        padding : 1vh 2.3vw;
        line-height : 1;
        background-color: $noir;
        color : $blanc;
        border-width : 0px;
        text-transform : none;
        font-variation-settings:
            "wdth" 125,
            "wght" $semibold;

        @media screen and (min-width : 300px) and (max-width : 749px) {
            font-size : 5vw;
            padding : .7vh 6vw;
            line-height : 1.5;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            font-size : 4vw;
            padding : .7vh 6vw;
            line-height : 1.2;
        }
    }

    &_nav{
        color : $noir;
        text-transform: uppercase;
        border : none;
        font-variation-settings:
            "wdth" 125,
            "wght" $bold;
        transition : .3s ease;     

        &.active {
            background-color: $noir;
            color : $blanc;
        }

        &:hover:not(.active){
            color : $noir;
        }

        @media screen and (max-width : 990px) {
            display : none;
        }
    }

    &_noir{
        color : $noir;
        border-color : $noir;
        
        &:hover{
            background-color: $noir;
            border-color : transparent;
            color : $blanc;
        }
    }

    &_blanc{
        color : $blanc;
        border-color : $blanc;

        &:hover{
            background-color: $blanc;
            border-color : transparent;
            color : $noir;
        }
    } 

    &_bgNoir{
        color : $blanc;
        border-color : $noir;
        background-color: $noir;
        
        &:hover{
            background-color: transparent;
            color : $noir;
        }
    }

    &_bgGris{
        color : $noir;
        border-color : $noir;
        background-color: $gris;
    }

    &_bgBlanc{
        color : $noir;
        border-color : $noir;
        background-color: $blanc;
        
        &:hover{
            background-color: transparent;
            color : $noir;
        }
    }

    &_bgBlancOmbre{
        color : $noir;
        border-color : $noir;
        background-color: $blanc;

        &:hover{
            background-color: transparent;
            color : $noir;
        }
    }

    &_bgPrimary{
        color : $noir;
        border-color : $noir;
        background-color: $primary;
        
        &:hover{
            background-color: transparent;
            color : $noir;
        }
    }
}