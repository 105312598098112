// Animation header

@keyframes headerImg{
    to {
        width : 33vw;
        height : 63vh;
    }
}

@keyframes headerImgMobile{
    to {
        height : 40vh;
    }
}


// Apparition sur les côtés

@keyframes slideSide{
    to {
        opacity : 1;
        transform : translateX(0);
    }
}


@keyframes slideUp{
    to {
        opacity : 1;
        transform : translateY(0);
    }
}


@keyframes slideNav{
    to{
        transform : translateX(-50%) translateY(0);       
    }
}



// Animation path svg

@keyframes drawPath {
    from {
        stroke-dashoffset: 1000; 
    }
    to {
        stroke-dashoffset: 0;
    }
}




// Animation rotation

@keyframes infiniteRotation {
    100%{
        transform : rotate(-360deg)
    }
}


@keyframes smoothRotation {
    0%{
        transform : rotate(15deg)
    }

    100%{
        transform : rotate(2.5deg)
    }
}


@keyframes rightRotation {
    0%{
        transform : rotate(-60deg)
    }

    100%{
        transform : rotate(15deg)
    }
}

@keyframes leftRotation {
    0%{
        transform : rotate(20deg)
    }

    100%{
        transform : rotate(-5deg)
    }
}


// Animation scale

@keyframes scaleAnimation {
    0%{
        transform : scale(1)
    }

    100%{
        transform : scale(1.3)
    }
}


// Animation variable font

@keyframes fontBold{
    0%{
        font-variation-settings:
        "wdth" 125,
        "wght" $thin;
    }
    50%{
        font-variation-settings:
        "wdth" 125,
        "wght" $heavy;
    }
    100%{
        font-variation-settings:
        "wdth" 125,
        "wght" $thin;
    }
}


// Animation fond grain

@keyframes grain {
    0%, 100% { transform:translate(0, 0) }
    10% { transform:translate(-5%, -10%) }
    20% { transform:translate(-10%, 5%) }
    30% { transform:translate(7%, -10%) }
    40% { transform:translate(-5%, 15%) }
    50% { transform:translate(-15%, 10%) }
    60% { transform:translate(15%, 0%) }
    70% { transform:translate(0%, 15%) }
    80% { transform:translate(3%, 10%) }
    90% { transform:translate(-10%, 10%) }
}