@import "../style.scss";

.projects{
    position : relative;
    margin-top : -20vh;
    z-index : 50;

    &_intro{
        padding : 40vh 10vw 50vh 10vw;
        border-radius : 100px 100px 0 0;
        background-color: $noir;

        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 10vh 30px 20vh 30px;
            border-radius : 50px 50px 0 0;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 20vh 60px 30vh 60px;
            border-radius : 50px 50px 0 0;
        }
        
        &-container{
            position : relative;
            display : flex;
            flex-direction: column;
            gap : 5vh;

    
            &--subtitle{
                width : 60vw;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : 100%;
                }
            }
    
            &--circledText{
                position : absolute;
                bottom : -5vw;
                right : 0;
                width : 14vw;
                height : auto;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    bottom : -17vw;
                }

                svg{
                    width : 100%;
                    height : auto;
                    object-fit: contain;

                    .path{
                        stroke-dasharray: 1000; 
                        stroke-dashoffset: 1000;   
                        transition : 2s ease; 

                        &.active{
                            stroke-dashoffset: 0; 
                        }
                    }

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 30vw;
                    }
                }
            }
        } 
    }
    
    &_container{
        position : relative;
        display : flex;
        flex-direction: column;
        gap : 30vh;
        padding : 20vh 10vw 70vh 10vw;
        background-color: $gris;
        border : 4px solid $noir;
        border-bottom : none;
        margin-top : -20vh;
        
        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 10vh 30px 40vh 30px;
            gap : 5vh;
            border-width : 3px;
            margin-top : 0;
        } 

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 10vh 60px 40vh 60px;
            gap : 5vh;
            border-width : 3px;
            margin-top : 0;
        } 

        &-absolute{
            position : absolute;
            width : calc(100% - 20vw);
            height : 605vh;
            @media screen and (min-width : 300px) and (max-width : 1000px) {
                display : none;
                width : calc(100% - 60px);
                height : 700vh;
            } 

            &--ombre{
                position : sticky;
                top : calc(15vh + 2vw);
                transition: 1s ease;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    top : 15vh;
                }
    
                .ombre-content{
                    display : flex;
                    justify-content: center;
                    align-items: center;
                    width : calc(100% - 2vw);
                    height : 75vh;
                    background-color: $primary;
                    border : 4px solid $noir;
                    border-radius : 50px; 
                    opacity : 0;
                    transition: opacity 1s ease;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        border-width : 3px;
                        border-radius : 30px;
                        height : 83vh;
                    }
                } 
    
                &.active{
                    margin-right : -4vw;
                    margin-left : 2vw;
                    transition: 1s ease;  
                    
                    .ombre-content{
                        opacity : 1;     
                        transition: 1s ease; 
                    }
                }
            }
        }
        
        &-content{
            position : sticky;
            top : 15vh;
            display : flex;
            justify-content: center;
            align-items: flex-end;
            width : 100%;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                top : 0;
                height : 100vh;
                padding-bottom : 3vh;
            }

            .bloc{
                display : flex;
                gap : 2.5vw;
                width : 100%;
                height : 75vh; 
                background-color: $gris;
                border : 4px solid $noir;
                border-radius : 50px; 
                padding : 2.5vw;

                @media screen and (min-width : 300px) and (max-width : 749px) {
                    flex-direction: column;
                    gap : 2.5vh;
                    height : 87vh;
                    padding : 20px;
                    border-radius : 30px;
                    border-width : 3px;
                }   

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    flex-direction: column;
                    gap : 2.5vh;
                    height : 87vh;
                    padding : 60px;
                    border-radius : 30px;
                    border-width : 3px;
                }   

                &_txt{
                    display : flex;
                    flex-direction: column;
                    gap : 5vh;
                    width : 100%;
                    height : 100%;

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        gap : 2.5vh;
                    }

                    .title{
                        display : flex;
                        flex-direction: column;
                        gap : 1vh;
                        width : 100%;


                        h3{
                            font-size : 3vw;
                            color : $noir;

                            @media screen and (min-width : 300px) and (max-width : 1000px) {
                                font-size : 6vw;
                            }
                        }

                        p{
                            font-size : 1.05vw;
                            line-height : 1.1;
                            text-transform : uppercase;
                            padding-right : 5vw;

                            @media screen and (min-width : 300px) and (max-width : 749px) {
                                padding-right : 0;
                                text-transform : none;
                                font-size : 3.3vw !important;
                            }

                            @media screen and (min-width : 760px) and (max-width : 1000px) {
                                padding-right : 0;
                                text-transform : none;
                                font-size : 3vw !important;
                            }
                        }
                    }

                    .description{
                        display : flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height : 100%;


                        @media screen and (min-width : 300px) and (max-width : 1000px) {
                            justify-content: flex-start;
                            gap : 2.5vh;
                            height : 100%;                
                        }

                        
                        &_txt{
                            display : flex;
                            flex-direction: column;   
                            gap : 1vh;

                            &-bloc{
                                display : flex;
                                gap : 1.5vw;
                                @media screen and (min-width : 300px) and (max-width : 1000px) {
                                    gap : 3vw;       
                                }

                                .carre{
                                    width : 8px;
                                    height : 8px;
                                    margin-top : 10px;
                                    background-color: $primary;  
                                    @media screen and (min-width : 300px) and (max-width : 749px) {
                                        width : 5px;
                                        height : 5px;
                                        margin-top : 6px;  
                                    }

                                    @media screen and (min-width : 750px) and (max-width : 1000px) {
                                        width : 10px;
                                        height : 10px;
                                        margin-top : 15px; 
                                    }
                                }

                                p{
                                    width : calc(100% - 8px - 2vw);
                                    font-size : 1.05vw;
                                    
                                    @media screen and (min-width : 300px) and (max-width : 749px) {
                                        width : calc(100% - 5px - 2vw);
                                        font-size : 3.3vw !important;
                                    }

                                    @media screen and (min-width : 760px) and (max-width : 1000px) {
                                        width : calc(100% - 10px - 2vw);
                                        font-size : 3vw !important;
                                    }
                                }
                            }  
                        } 
                        
                        &_perks{
                            display : flex;
                            flex-wrap: wrap;
                            column-gap : 1vw;
                            row-gap : .5vw;

                            @media screen and (min-width : 300px) and (max-width : 1000px) {
                                column-gap : 1vw;
                                row-gap : 1vw;  
                            }

                            .bouton{ 
                                padding : .8vh 2vw;
                                border-width : 3px;
                                cursor : url(../img/icons/cursor.svg), auto;
                                font-size : 1vw;

                                @media screen and (min-width : 300px) and (max-width : 1000px) {
                                    font-size : 2.5vw !important;
                                    flex-grow: 1;
                                    padding : .5vh 5vw;
                                    border-width : 2px;

                                    &:last-child{
                                        flex-grow: initial
                                    }
                                }
                            }
                        }
                    }  
                }

                &_img{
                    width : 50vw;
                    height : 100%;
                    border : 4px solid $noir;
                    border-radius : 30px;
                    overflow : hidden;

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 100%;  
                        margin-left : 0; 
                        border-radius : 15px;    
                        border-width : 3px;        
                    }

                    &-link{
                        position : relative;
                        width : 100%;
                        height : 100%;
                        z-index : 5;                               

                        img{
                            width : 100%;
                            height : 100%;
                            object-fit: cover;
                        }

                        &--btn{
                            position : absolute;
                            bottom : 1vw;
                            right : 1vw;
                            border-radius : 30px 0 0 0;
                            z-index : 10;
                            transition : .5s ease;
                            @media screen and (min-width : 300px) and (max-width : 1000px) {
                                bottom : 2vw;
                                right : 2vw;
                                width : fit-content;
                                height : fit-content;
                                border-radius : 100vmax;
                                padding : 1.3vh 5vw;  
                            }

                            .bouton{
                                border : none;
                                @media screen and (min-width : 300px) and (max-width : 1000px) {
                                    font-size : 2.5vw;             
                                }
                            }
                        }

                        &:hover{
                            .mockup_bouton{
                                .bouton{
                                    background-color: $noir;
                                    color : $blanc;
                                }
                            } 
                        }
                    }  

                    .bouton {
                        position: fixed;
                        font-size : 1vw;
                        color : $blanc;
                        transform: translate(-80%, -80%);
                        border-width : 3px;
                        border : none;
                        pointer-events: none;
                        transition : none;
                        z-index : 100;
                    }
                }
            }
        }       
    } 
}