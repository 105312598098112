h1{
    position : relative;
    width : 100%;
    font-size : 5.5vw;
    line-height : 1.2;
    text-transform : uppercase;
    color : $noir;
    font-variation-settings:
        "wdth" 125,
        "wght" $heavy;
    opacity : 0;
    transform : translateX(-100%);
    animation : slideSide 1.2s ease .7s forwards;

    @media screen and (min-width : 300px) and (max-width : 749px) {
        font-size : 11.5vw;
        line-height : 1.5;
    }

    @media screen and (min-width : 750px) and (max-width : 1000px) {
        font-size : 11.5vw;
    }

    span{
        display : inline-block;
        font-size : 2.3vw;
        line-height : 1;
        text-transform : none;
        font-variation-settings:
            "wdth" 125,
            "wght" $semibold;

        @media screen and (min-width : 300px) and (max-width : 749px) {
           font-size : 5vw;
           line-height : 1.2;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            font-size : 4vw;
            line-height : 1.2;
        }
    }
}

h2{
    font-size : 6vw;
    text-transform: uppercase;
    color : $gris;
    font-variation-settings:
        "wdth" 125,
        "wght" $heavy;

    @media screen and (min-width : 300px) and (max-width : 1000px) {
        font-size : 8vw;
        line-height : 1.2;
    }

    span{
        color : $primary;
    }
}

h3{
    font-size : 3.5vw;
    line-height : 1;
    color : $noir;
    text-transform: uppercase;
    font-variation-settings:
        "wdth" 125,
        "wght" $heavy;
        @media screen and (min-width : 300px) and (max-width : 1000px) {
            font-size : 8vw;
        }
}

h4{
    font-size : 3vw;
    line-height : 1.1;
    color : $blanc;
    text-transform: uppercase;
    font-variation-settings:
        "wdth" 125,
        "wght" $heavy;
    @media screen and (max-width : 990px) {
        font-size : 5vw;
    }
}


p, button{
    font-size : 1.5vw;
    line-height : 1.5;
    color : $noir;
    font-variation-settings:
        "wdth" 125,
        "wght" $medium;

        &.title{
            color : $primary;
            text-transform : uppercase;
            font-variation-settings:
                "wdth" 125,
                "wght" $bold;    
        }

        &.subtitle{
            font-size : 1.7vw;
            color : $blanc;
            font-variation-settings:
                "wdth" 125,
                "wght" $bold;

            @media screen and (min-width : 300px) and (max-width : 1000px) {
                font-size : 4.5vw;
            }
        }

    @media screen and (min-width : 300px) and (max-width : 749px) {
        font-size : 4.5vw;
    }

    @media screen and (min-width : 750px) and (max-width : 1000px) {
        font-size : 4vw;
    }
}


a{
    text-decoration: none;
    color : inherit;
    padding : 0;
    margin : 0;

    &:hover{
        cursor : url(../img/icons/cursor-pointer.svg), auto;
    }
}