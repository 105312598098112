@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wdth,wght@0,62..125,100..900;1,62..125,100..900&display=swap');


$font : "Archivo", sans-serif;

$thin : 100;
$extralight : 200;
$light : 300;
$regular : 400;
$medium : 500;
$semibold : 600;
$bold : 700;
$black : 800;
$heavy : 900;

// weight : 100 - 900
// width : 62 - 125
// italic : 0 -1

