@import "../style.scss";

.footer{
    position : relative;
    display : flex;
    flex-direction: column;
    justify-content: flex-end;
    width : 100%;
    height : 100vh;
    z-index : 105;
    margin-top : -30vh; 

    &_container{
        position : relative;
        display : flex;
        flex-direction: column;
        justify-content: flex-end;
        gap : 15vh;
        width : 100%;
        height : 94vh;
        background-color: $noir;
        padding : 0 10vw 5vh 10vw;
        border-radius : 100px 100px 0 0;
        overflow : hidden;
        
        
        @media screen and (min-width : 300px) and (max-width : 749px) {
            justify-content: space-between;
            gap : 15vh;
            padding : 15vh 30px 5vh 30px;
            border-radius : 50px 50px 0 0;
            height : 90vh;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            justify-content: space-between;
            gap : 15vh;
            padding : 15vh 60px 5vh 60px;
            border-radius : 50px 50px 0 0;
            height : 90vh;
        }

        &::after {
            position: absolute;
            content: "";
            top: -100%;
            left: -50%;
            width: 300%;
            height: 300%;
            animation: grain 10s steps(10) infinite;
            background-image: url("../img/illustrations/noise-effect.png");
            opacity: .2;
            z-index : 2;
        }

        &-title{
            position : relative;
    
            h2{
                font-size : 7.5vw;
                display : flex;
                flex-direction: column;
                
    
                span{
                    line-height : .9;
                    font-variation-settings:
                        "wdth" 125,
                        "wght" $heavy;

                    &:nth-of-type(1){
                        color : $blanc;
                    } 
    
                    &:nth-of-type(2){
                        align-self: flex-end;
                    }  
                    
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        font-size : 10vw;
                        line-height : 1;
                    }
                }
            }
    
            &--img{
                position : absolute;
                top : 20vh;
                left : 22vw;
                width : 7vw;
                height : 7vw;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    top : 15vw;
                    left : 2.5vw;
                    width : 18vw;
                    height : 8vw;
                }
    
                img{
                    width : 100%;
                    height : 100%;
                    object-fit: contain;
                }
            }
        }
    
        &-links{
            display : flex;
            justify-content: center;
            gap : 2vw;
    
            @media screen and (min-width : 300px) and (max-width : 1000px) {
                flex-direction: column;
                align-items: center;
                gap : 2.5vh;
            }
    
            .bouton{
                border-width : 3px;
                font-size : 1.2vw;
                z-index : 3;
                @media screen and (min-width : 300px) and (max-width : 749px) {
                    text-align : center;
                    font-size : 5vw;
                    padding : 2.5vw 10vw;
                }

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    font-size : 4vw;
                }
            }
        }
    
        &-copyright{
            display : flex;
            justify-content: space-between;
    
            p, a{
                color : $blanc;
                font-size : .8vw;
                font-weight : $light;
                text-transform: uppercase;
                @media screen and (max-width : 990px) {
                    font-size : 2vw !important;
                }
            }
        }
    } 
}