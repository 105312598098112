@import "../style.scss";

.skills{
    position : relative;
    margin-top : -20vh;
    z-index : 5;

    &_intro{
        padding : 30vh 10vw 50vh 10vw;
        border-radius : 100px 100px 0 0;
        border : 4px solid $noir;
        background-color: $gris;

        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 10vh 30px 30vh 30px;
            border-radius : 50px 50px 0 0;
            border-width : 3px;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 20vh 60px 30vh 60px;
            border-radius : 50px 50px 0 0;
            border-width : 3px;
        }
        
        &-container{
            position : relative;
            display : flex;
            flex-direction: column;
            gap : 5vh;

            &--title{
                h2{
                    color : $noir;
                }
            }
    
            &--subtitle{
                width : 60vw;
                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    width : 100%;
                }

                .subtitle{
                    color : $noir;
                }
            }
    
            &--circledText{
                position : absolute;
                bottom : -5vw;
                right : 0;
                width : 14vw;
                height : auto;

                @media screen and (min-width : 300px) and (max-width : 1000px) {
                    bottom : -17vw;
                }

                svg{
                    width : 100%;
                    height : auto;
                    object-fit: contain;

                    .path{
                        stroke-dasharray: 1000; 
                        stroke-dashoffset: 1000;   
                        transition : 2s ease; 

                        &.active{
                            stroke-dashoffset: 0; 
                        }
                    }

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 30vw;
                    }
                }
            }
        } 
    }

    &_presentation{
        position : relative;
        background-color: $primary;
        border : 4px solid $noir;
        border-top : none;
        padding : 40vh 10vw 60vh 10vw;
        z-index : 10;
        @media screen and (min-width : 300px) and (max-width : 749px) {
            padding : 45vh 30px 50vh 30px; 
            border-width : 3px;
        }

        @media screen and (min-width : 750px) and (max-width : 1000px) {
            padding : 45vh 60px 50vh 60px; 
            border-width : 3px;
        }
    
        &-container{
            position : relative;
            display : flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap : 1.5vw;
            width : 100%;
            margin-top : -60vh;
           
            @media screen and (min-width : 300px) and (max-width : 1000px) {                   
                display : grid;
                grid-template-columns: 1fr;
                gap : 5vh;   
            }
    
            .bloc{
                position : relative;
                display : flex;
                flex-direction: column;
                justify-content: space-between;
                border : 4px solid $noir;
                background-color: $blanc;
                border-radius : 30px;
                padding : 2.5vw;
                min-width : 18.5vw; 
                width : 18.5vw;
                min-height : 50vh;

                @media screen and (min-width : 300px) and (max-width : 749px) {
                    gap : 8vh;
                    width : calc(100% - 5vw);
                    min-width : calc(100% - 5vw);
                    min-height : 100%;
                    height : 100%;
                    padding : 30px;
                }

                @media screen and (min-width : 750px) and (max-width : 1000px) {
                    gap : 8vh;
                    width : calc(100% - 5vw);
                    min-width : calc(100% - 5vw);
                    min-height : 100%;
                    height : 100%;
                    padding : 60px;
                }
    
                &:not(.txt, .projet){
                    &::after{
                        position : absolute;
                        content : "";
                        bottom : -1vw;
                        left : 1vw;
                        width : 100%;
                        height : 100%;
                        background-color: $noir;
                        border-radius : 30px;
                        z-index : -1;
                        @media screen and (min-width : 300px) and (max-width : 1000px) {
                            bottom : -5vw;
                            left : 5vw;
                        }
                    }
                }
    
                &-icon{
                    width : fit-content;
                    height : 4vw;
                    overflow : hidden;
                    @media screen and (min-width : 300px) and (max-width : 749px) {
                        height : 20vw;
                    }

                    @media screen and (min-width : 750px) and (max-width : 1000px) {
                        height : 15vw;
                    }
    
                    svg{
                        width : auto;
                        height : 100%;
                        object-position: left;
                        stroke : $primary;
                        transition : 0 ease;
                    }
                }
    
                &-content{
                    display : flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    gap : 2vh;
                    width : 100%;
                    height : 100%;
                    overflow : hidden;
    
                    &--title{
                        h3{
                            font-size : 1.7vw;
                            text-transform: uppercase;
                            font-variation-settings:
                                "wdth" 125,
                                "wght" $heavy;
    
                                @media screen and (min-width : 300px) and (max-width : 749px) {
                                    font-size : 8vw;
                                }
                                
                                @media screen and (min-width : 750px) and (max-width : 1000px) {
                                    font-size : 7vw;
                                }
                        } 
                    }
    
                    &--list{
                        p{
                            font-size : .9vw;
                            @media screen and (min-width : 300px) and (max-width : 1000px) {
                                font-size : 4vw;
                            }
                        }
                    }
                }
    
                &.txt{
                    position : relative;
                    width : 38.7vw;           
                    background-color : $noir;
                    border : none;
                    overflow : hidden;

                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        width : 100%;
                        min-width : 100%;
                        min-height : 100%;
                        height : 100%;
                    }
    
                    &::after {
                        position: absolute;
                        content: "";
                        top: -100%;
                        left: -50%;
                        width: 300%;
                        height: 300%;
                        animation: grain 10s steps(10) infinite;
                        background-image: url("../img/illustrations/noise-effect.png");
                        opacity: .15;
                        z-index : 2;
                    }
    
                    .bloc-content{
                        justify-content: space-between;
                        flex : 1;

                        @media screen and (min-width : 300px) and (max-width : 749px) {
                            gap : 8vh;
                        }
    
                        &--title{
                            z-index : 5;

                            h3{
                                color : $blanc;
                            }
                        }
    
                        &--txt{
                            z-index : 5;

                            p{
                                line-height : 1.2;
                                color : $blanc;

                                @media screen and (min-width : 300x) and (max-width : 1000px) {
                                    font-size : 4vw;
                                }
                            }
                        }
                    }  
                }
    
                &.projet{
                    justify-content: flex-end;
                    border : none;
                    padding : 2.5vw 0;
                    background-color: transparent;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {
                        display : none;
                    }
    
                    p{
                        color : $noir;
                        text-transform : uppercase;
    
                        &:nth-of-type(1){
                            font-size : 7.4vw; 
                            line-height : .7; 
                            font-variation-settings:
                                "wdth" 125,
                                "wght" $heavy; 
                                @media screen and (min-width : 300px) and (max-width : 1000px) {
                                font-size : 22vw;
                            }
                        }
                        &:nth-of-type(2){
                            font-size : 2.5vw;  
                            line-height : 1; 
                            font-variation-settings:
                                "wdth" 125,
                                "wght" $heavy; 
                                @media screen and (min-width : 300px) and (max-width : 1000px) {
                                font-size : 8vw;
                            }
                        }
                    }
                }
    
                &:nth-of-type(7){
                    visibility : hidden;
                    @media screen and (min-width : 300px) and (max-width : 1000px) {                 
                        display : none;
                    }
                }
            }
    
            .pastille{
                position : relative;
                flex : 1;
    
                img{
                    position : absolute;
                    top : -6vw;
                    right : -1vw;
                    width : 14vw;
                    height : auto;
                    z-index : 5;
    
                    &.txt{
                        animation : infiniteRotation 15s linear infinite forwards;
                    }
                }
            }
        }  
    }
}