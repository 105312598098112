@import "../style.scss";

.nav{
    position : fixed;
    top : 2.5vh;
    left : 50%;
    transform : translateX(-50%) translateY(-200%);
    display : flex;
    gap : 1vw;  
    border-radius: 100vmax;
    width : fit-content;
    padding : 1vh 1vw;
    border : 4px solid $noir;
    background-color: $blanc;
    animation : slideNav 1s ease .1s forwards;
    z-index : 111;

    @media screen and (min-width : 300px) and (max-width : 745px) {
        top : 30px;
        transform : translateX(-50%) translateY(calc(-100% + (-40px)));
        justify-content: flex-end;
        width : 100%;
        padding : 0 30px;
        border-radius : 0;
        border : none;
        background-color: transparent;
    }

    @media screen and (min-width : 750px) and (max-width : 1000px) {
        top : 60px;
        transform : translateX(-50%) translateY(calc(-100% + (-40px)));
        justify-content: flex-end;
        width : 100%;
        padding : 0 60px;
        border-radius : 0;
        border : none;
        background-color: transparent;
    }

    &_menu{
        position : relative;
        display : none;
        width : 30px;
        height : 23px;

        &-barre{
            position : absolute;
            left : 0;
            width : 100%;
            height : 3px;
            border-radius : 100vmax;
            background-color: $noir;
            will-change : transform;
            transition : .5s ease;

            &:nth-child(1){
                top : 0;
                transform-origin : top;
            }

            &:nth-child(2){
                top : 50%;
                transform : translateY(-50%);
            }

            &:nth-child(3){
                bottom : 0;
                transform-origin : bottom;
            }
        }

        &.active{
            .nav_menu-barre:nth-child(1){
                top : 50%;
                transform : translateY(-50%) rotate(45deg) ;
                background-color: $blanc;
            }

            .nav_menu-barre:nth-child(2){
                opacity : 0;
            }

            .nav_menu-barre:nth-child(3){
                top : 50%;
                transform : translateY(-50%) rotate(-45deg);
                background-color: $blanc;
            }
        }

        @media screen and (max-width:990px){
            display : flex;      
        }
    }  
}